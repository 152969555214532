<template>
    <v-card>
        <v-card-text>
            <FiltrosSticky icono="mdi-filter-settings" :modo-sticky="false">
                <v-row justify="center">
                    <v-col cols="12" lg="4">
                        <v-select 
                            v-model="filtros.anio"
                            outlined 
                            hide-details 
                            clearable
                            :items="aniosFiscales.data"
                            :loading="aniosFiscales.isLoading"
                            :disabled="resumenInstitucional.isLoading"
                            item-value="id"
                            item-text="anio"
                            label="Año"
                            placeholder="Seleccione un año"
                        />
                    </v-col>
                    <v-col cols="12" lg="4">
                        <InstitucionesAutoComplete
                            :value.sync="filtros.institucion"
                            :evento-reinicio="eventoRestablecerFiltros"
                            :listener="institucionesListener"
                            :autocomplete-props="{ disabled: resumenInstitucional.isLoading }"
                        />
                    </v-col>
                    <v-col cols="12" lg="4">
                        <div class="d-flex justify-center align-center flex-wrap-reverse flex-md-nowrap" style="gap: 16px;">
                            <v-btn 
                                color="primary"
                                class="flex-grow-1 flex-shrink-1"
                                x-large
                                @click.stop="hacerBusquedaConFiltros"
                                :loading="resumenInstitucional.isLoading"
                            >
                                Buscar
                            </v-btn>
                            <v-btn 
                                color="primary" 
                                class="flex-grow-1 flex-shrink-1"
                                outlined
                                x-large
                                @click.stop="restablecerInformacion"
                                :disabled="resumenInstitucional.isLoading"
                            >
                                Limpiar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </FiltrosSticky>

            <Estadisticas 
                class="mt-4" 
                :evento-busqueda="eventoBusquedaFiltros"
                :listener="estadisticasListener"
            />

            <DataTableComponent
                :headers="headers"
                :items="resumenInstitucional.data"
                class="mt-8"
                dense
                no-gutters
                @paginar="manejarPaginacionRegistros"
                :total_registros="totalRegistros"
                :show_loading="resumenInstitucional.isLoading"
                v-models:pagina="paginacion.pagina"
                v-models:select="paginacion.registrosPorPagina"
            >
                <template v-slot:item.porcentaje_avance="{ item }">
                    {{ mostrarPorcentaje(item.porcentaje_avance) }}
                </template>
                <template v-slot:item.acciones="{ item }">
                    <div class="d-flex align-center justify-center" style="gap: 16px;">
                        <v-btn @click.stop="mostrarDetalleInstitucion(item)" icon>
                            <v-icon color="secondary">mdi-eye</v-icon>
                        </v-btn>
                    </div>
                </template>
            </DataTableComponent>

            <ModalProcesos
                :is-open.sync="modalVerProcesosAbierta" 
                :evento-busqueda="eventoModalProcesos"
                :listener="modalProcesosListener"
            />
        </v-card-text>
    </v-card>
</template>
<script>
import InstitucionesAutoComplete from '@/components/compartidos/InstitucionesAutoComplete.vue';
import { createLoadable, createPageable, setLoadableResponse, setPageableResponse, toggleLoadable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import FiltrosSticky from '../components/FiltrosSticky.vue';
import { Listener, Observable } from '@/utils/observable';
import ModalProcesos from './ModalProcesos.vue';
import Estadisticas from './Estadisticas.vue';
import { mostrarPorcentaje } from '@/utils/data';

const filtrosIniciales = {
    anio: null,
    institucion: null,
}

export default {
    components: { FiltrosSticky, InstitucionesAutoComplete, Estadisticas, DataTableComponent, ModalProcesos },
    name: 'PanelCierreContratosInstitucion',
    data: () => ({
        // UI
        filtros: { ...filtrosIniciales },
        institucionesListener: new Listener('InstitucionesListener'),
        estadisticasListener: new Listener('EstadisticasListener'),
        modalProcesosListener: new Listener('ModalProcesosListener'),

        unsubscribers: [],
        eventoRestablecerFiltros: 'RESTABLECER_FILTROS_REPORTERIA_CIERRE_CONTRATOS_INSTITUCION',
        eventoBusquedaFiltros: 'BUSCAR_INFORMACION_REPORTERIA_CIERRE_CONTRATOS_INSTITUCION',
        eventoModalProcesos: 'CARGAR_PROCESOS_REPORTERIA_CIERRE_CONTRATOS_INSTITUCION',

        modalVerProcesosAbierta: false,
        filtrosActivos: { ...filtrosIniciales },
        filaActiva: null,
        modalVerProcesosAbierta: false,

        // DATA FILTROS
        aniosFiscales: createLoadable([]),

        // DATA
        resumenInstitucional: createPageable([], 10),
        paginacion: {
            pagina: 1,
            registrosPorPagina: 10,
        },
    }),
    computed: {
        headers() {
            return [
                {
                    align: 'center',
                    sortable: false,
                    text: 'Institución',
                    value: 'nombre'
                },
                {
                    align: 'center',
                    sortable: false,
                    text: 'Procesos identificados',
                    value: 'procesos_identificados'
                },
                {
                    align: 'center',
                    sortable: false,
                    text: 'Procesos finalizados',
                    value: 'procesos_finalizados'
                },
                {
                    align: 'center',
                    sortable: false,
                    text: 'Procesos pendientes',
                    value: 'procesos_pendientes'
                },
                {
                    align: 'center',
                    sortable: false,
                    text: 'Porcentaje de avance',
                    value: 'porcentaje_avance'
                },
                {
                    align: 'center',
                    sortable: false,
                    text: 'Acciones',
                    value: 'acciones'
                },
            ];
        },
        totalRegistros() {
            return this.resumenInstitucional.pagination.total_rows;
        },
    },
    methods: {
        // UI
        mostrarPorcentaje,
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
            this.paginacion = { pagina, registrosPorPagina };

            this.cargarResumenInstitucional();
        },
        restablecerInformacion() {
            this.filtros = { ...filtrosIniciales };
            this.filtrosActivos = { ...filtrosIniciales };
            this.paginacion = { pagina: 1, registrosPorPagina: 10 };
            this.filaActiva = null;

            Observable.emit(this.eventoRestablecerFiltros);
            Observable.emit(this.eventoBusquedaFiltros, {});
            Observable.emit(this.eventoModalProcesos, { 
                id_institucion: null,
                id_anio_fiscal: null,
            });

            this.paginacion = { pagina: 1, registrosPorPagina: 10 };
            this.cargarResumenInstitucional();
        },
        hacerBusquedaConFiltros() {
            this.paginacion = { pagina: 1, registrosPorPagina: 10 };
            this.cargarResumenInstitucional();

            this.filtrosActivos = { ...this.filtros }
            Observable.emit(this.eventoBusquedaFiltros, { 
                id_institucion: this.filtros.institucion,
                id_anio_fiscal: this.filtros.anio,
            });
        },
        mostrarDetalleInstitucion(institucion) {
            this.filaActiva = { ...institucion };
            this.filtrosActivos = { ...this.filtros }
            this.modalVerProcesosAbierta = true;

            Observable.emit(this.eventoModalProcesos, { 
                id_institucion: institucion.id,
                id_anio_fiscal: this.filtros.anio,
            });
        },
        // DATA
        async cargarAniosFiscales() {
            toggleLoadable(this.aniosFiscales);
            const { data } = await this.services.PacProcesos.cargarAniosFiscales();
            setLoadableResponse(this.aniosFiscales, { data: Array.isArray(data) ? data : [] });

            return data;
        },
        async cargarResumenInstitucional() {
            const filtros = {
                pagination: true,
                page: this.paginacion.pagina,
                per_page: this.paginacion.registrosPorPagina,
                id_institucion: this.filtros.institucion,
                id_anio_fiscal: this.filtros.anio,
            };

            toggleLoadable(this.resumenInstitucional);
            const { data, headers } = await this.services.ReporteriaCierreContratosInstitucion.cargarInstituciones(filtros);
            setPageableResponse(this.resumenInstitucional, data, headers);
        },
    },
    created() {
        this.cargarAniosFiscales();
        this.cargarResumenInstitucional();

        this.unsubscribers = [
            Observable.getInstance().subscribe(this.eventoRestablecerFiltros, this.institucionesListener),
            Observable.getInstance().subscribe(this.eventoBusquedaFiltros, this.estadisticasListener),
            Observable.getInstance().subscribe(this.eventoModalProcesos, this.modalProcesosListener),
        ];
    },
    beforeDestroy() {
        this.unsubscribers.forEach((unsubscriber) => unsubscriber());
    },
}
</script>