<template>
    <v-dialog v-model="isOpen" max-width="1600px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 py-0 flex-column " style="position: sticky; top: 0; z-index: 1; background: #fff">
                <div class="px-4 py-4 d-flex" style="width: 100%">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal" 
                        :disabled="procesos.isLoading"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <v-divider style="width: 100%" />
            </v-card-title>
            <v-card-text class="px-4 py-4">
                <DataTableComponent
                    :items="procesos.data"
                    :headers="headers"
                    :total_registros="totalRegistros"
                    :show_loading="procesos.isLoading"
                    v-models:pagina="paginacion.pagina"
                    v-models:select="paginacion.registrosPorPagina"
                    dense
                    no-gutters
                    @paginar="manejarPaginacion"
                >
                    <template v-slot:item.fecha_inicio_proceso="{ item }">
                        {{ formatDate(item.fecha_inicio_proceso, "DD-MM-YYYY") }}
                    </template>
                    <template v-slot:item.fecha_contratacion="{ item }">
                        {{ formatDate(item.etapa_contratacion?.fecha_hora_fin, "DD-MM-YYYY") }}
                    </template>
                    <template v-slot:item.estado="{ item }">
                        <v-chip
                            :color="item.SeguimientoProceso.id !== 3 ? 'success' : 'error'"
                            class="white--text"
                            label
                        >
                            {{ item.SeguimientoProceso.id !== 3 ? 'Finalizado' : 'Pendiente' }}
                        </v-chip>
                    </template>
                </DataTableComponent>
                <div 
                    class="d-flex justify-center align-center mt-4" 
                    style="position: sticky; bottom: 0; background: white; gap: 16px;"
                >
                    <v-btn 
                        color="primary" 
                        large
                        @click.stop="cerrarModal"
                        :disabled="procesos.isLoading"
                    >
                        Cerrar
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { createPageable, setPageableResponse, togglePageable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { toMoneyFormat } from '@/utils/data';
import { formatDate } from '@/utils/datetime';

export default {
    name: 'ModalProcesos',
    components: { DataTableComponent },
    props: {
        isOpen: { type: Boolean },
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        procesos: createPageable([], 10),
        paginacion: {
            pagina: 1,
            registrosPorPagina: 10,
        },
        institucionActiva: null,
        anioActivo: null,
    }),
    computed: {
        headers() {
            return [
                { align: 'center', sortable: false, text: 'Nombre', value: 'nombre_proceso' },
                { align: 'center', sortable: false, text: 'Código', value: 'codigo_proceso', width: '20%', },
                { align: 'center', sortable: false, text: 'Fecha de inicio', value: 'fecha_inicio_proceso' },
                { align: 'center', sortable: false, text: 'Fecha de contratación', value: 'fecha_contratacion' },
                { align: 'center', sortable: false, text: 'Seguimiento', value: 'SeguimientoProceso.nombre' },
                { align: 'center', sortable: false, text: 'Estado', value: 'estado' },
            ];
        },
        totalRegistros() {
            return this.procesos.pagination.total_rows;
        },
    },
    methods: {
        // UI
        toMoneyFormat,
        formatDate,
        cerrarModal() {
            this.institucionActiva = null;
            this.anioActivo = null;
            this.procesos = createPageable([], 10);
            
            this.paginacion = {
                pagina: 1,
                registrosPorPagina: 10,
            }
            this.$emit('update:is-open', false);
        },
        manejarPaginacion(paginacion) {
            const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
            this.paginacion = { pagina, registrosPorPagina }

            this.cargarProcesos();
        },
        manejarEventos(evento, payload) {
            if (evento !== this.eventoBusqueda) return;

            this.institucionActiva = payload.id_institucion;
            this.anioActivo = payload.id_anio_fiscal;

            this.cargarProcesos();
        },
        // DATA
        async cargarProcesos() {
            if (!this.institucionActiva) return;

            const filtros = {
                pagination: true,
                per_page: this.paginacion.registrosPorPagina,
                page: this.paginacion.pagina,
                id_anio_fiscal: this.anioActivo,
            }

            togglePageable(this.procesos);
            const { data, headers } = await this.services.ReporteriaCierreContratosInstitucion.cargarProcesos(this.institucionActiva, filtros);
            setPageableResponse(this.procesos, data, headers);
        },
    },
    created() {
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>