<template>
    <v-row align="stretch">
        <v-col cols="12" md="6" lg="3">
            <v-card style="height: 100%">
                <v-card-title>
                        <div class="d-flex align-center" style="gap: 8px;">
                            <v-icon style="vertical-align: middle;">mdi-text-box-search</v-icon>
                            <span class="text-subtitle-2 font-weight-bold text-uppercase" style="word-break: break-word;">
                                Procesos Identificados
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon 
                                            v-on="on" 
                                            v-bind="attrs" 
                                            size="20" 
                                            color="secondary"
                                            >
                                            mdi-information
                                        </v-icon>
                                    </template>
                                    <span>Total de procesos que no estaban en contratación</span>
                                </v-tooltip>
                            </span>
                        </div>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <div>
                        <span class="text-h5 font-weight-bold">{{ mostrarSiNoEstaCargando(estadisticas.data?.procesos_identificados, estadisticas.isLoading) }}</span>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="3">
            <v-card style="height: 100%">
                <v-card-title>
                    <div class="d-flex align-center" style="gap: 8px;">
                        <v-icon style="vertical-align: middle;">mdi-text-box-check</v-icon>
                        <span class="text-subtitle-2 font-weight-bold text-uppercase" style="word-break: break-word;">
                            Procesos Finalizados
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                        v-on="on" 
                                        v-bind="attrs" 
                                        size="20" 
                                        color="secondary"
                                    >
                                        mdi-information
                                    </v-icon>
                                </template>
                                <span>Total procesos identificados que pasaron a contratación</span>
                            </v-tooltip>
                        </span>
                    </div>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <div>
                        <span class="text-h5 font-weight-bold">
                            {{ mostrarSiNoEstaCargando(estadisticas.data?.procesos_finalizados, estadisticas.isLoading) }}
                        </span>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="3">
            <v-card style="height: 100%">
                <v-card-title>
                    <div class="d-flex align-center" style="gap: 8px;">
                        <v-icon style="vertical-align: middle;">mdi-text-box-remove</v-icon>
                        <span class="text-subtitle-2 font-weight-bold text-uppercase" style="word-break: break-word;">
                            Procesos pendientes
                        </span>
                    </div>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <div>
                        <span class="text-h5 font-weight-bold">
                            {{ mostrarSiNoEstaCargando(estadisticas.data?.procesos_pendientes, estadisticas.isLoading) }}
                        </span>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="3">
            <v-card style="height: 100%">
                <v-card-title>
                    <div class="d-flex align-center" style="gap: 8px;">
                        <v-icon style="vertical-align: middle;">mdi-chart-pie</v-icon>
                        <span class="text-subtitle-2 font-weight-bold text-uppercase" style="word-break: break-word;">
                            Porcentaje de avance
                        </span>
                    </div>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <div>
                        <span class="text-h5 font-weight-bold">
                            {{ mostrarPorcentaje(estadisticas.data?.porcentaje_avance, estadisticas.isLoading) }}
                        </span>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
      </v-row>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { mostrarSiNoEstaCargando, mostrarPorcentaje } from '@/utils/data';

export default {
    name: 'Estadisticas',
    props: {
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        estadisticas: createLoadable(null),
    }),
    methods: {
        //UI
        mostrarSiNoEstaCargando,
        mostrarPorcentaje,
        manejarEventos(evento, payload) {
            if (evento !== this.eventoBusqueda) return;

            this.cargarEstadisticas(payload);
        },
        // DATA
        async cargarEstadisticas(filtros) {
            toggleLoadable(this.estadisticas);
            const { data } = await this.services.ReporteriaCierreContratosInstitucion.cargarEstadisticas(filtros);
            setLoadableResponse(this.estadisticas, data);
        },
    },
    created() {
        this.cargarEstadisticas();    
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>